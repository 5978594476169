import { ConflictError, UnauthorizedError } from 'src/errors/http_errors';
import { User } from 'src/models/user';
import { CritterType } from 'src/utils/critterTypes';

async function fetchData(input: RequestInfo, init?: RequestInit) {
  const response = await fetch(input, init);

  if (response.ok) {
    return response;
  } else {
    const errorBody = await response.json();
    const errorMessage = errorBody.error;
    if (response.status === 401) {
      throw new UnauthorizedError(errorMessage);
    } else if (response.status === 409) {
      throw new ConflictError(errorMessage);
    } else {
      throw Error(`Request failed with status: ${response.status}, message: ${errorMessage}`);
    }
  }
}

export async function getLoggedInUser(): Promise<User> {
  const response = await fetchData('/api/users', { method: 'GET' });
  return response.json();
}

export interface SignUpCredentials {
  email: string,
  password: string,
  name: string,
}

export async function signup(credentials: SignUpCredentials): Promise<User> {
  const response = await fetchData('/api/users/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
    credentials: 'include',
  });

  return response.json();
}

export interface LoginCredentials {
  email: string,
  password: string,
}

export async function login(credentials: LoginCredentials): Promise<User> {
  const response = await fetchData('/api/users/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
    credentials: 'include',
  });
  return response.json();
}

export async function logout() {
  await fetchData('/api/users/logout', { method: 'GET' });
}

export interface ChangeCritterDataEncodedString {
  encodedString: string,
}

export async function changeCritterData(
  critter: CritterType,
  encodedString: ChangeCritterDataEncodedString,
): Promise<User> {
  const response = await fetchData(`/api/users/updateCritter/${critter}`, {
    method: 'PATCH',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(encodedString),
    credentials: 'include',
  });

  return response.json();
}

export async function getAll() {
  await fetchData('/api/users/display', { method: 'GET' });
}
